<template>
  <div class="labor-contract-wrapper flex">
    <div class="search-content searchBox">
      <div class="search-item searchItem">
        <span>姓名：</span>
        <el-input type="text" v-model="filter.staffName" placeholder="请输入姓名" size="medium" clearable></el-input>
      </div>
      <div class="search-item searchItem">
        <span>员工编号：</span>
        <el-input type="text" v-model="filter.jobNumber" placeholder="请输入编号" size="medium" clearable></el-input>
      </div>
      <div class="search-item searchItem">
        <span>审核状态：</span>
        <el-select v-model="filter.status" size="medium">
          <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value" ></el-option>
        </el-select>
      </div>
      <div class="search-item searchItem">
        <div  style="margin-left: 16px">
          <el-button type="primary" class="btn-search" @click="search()" size="medium">查询</el-button>
          <el-button type="primary" class="btn-search" @click="exportData()" size="medium">导出</el-button>
          <el-button  class="btn-reset" @click="resetSearch" size="medium">重置</el-button>
        </div>
      </div>
    </div>
    <div class="table-content inline-flex contentBox">
      <div class="contentTool">
        <el-button @click="addData" type="primary" size="medium">新增</el-button>
      </div>
      <el-table :data="tableData" class="contentTable" size="medium">
        <template v-for="col in cols">
          <el-table-column :label="col.label" width="col.width" v-if="col.prop==='operate'">
            <template  slot-scope="scope">
              <span class="mgr10 blue" v-if="scope.row.status!==6" @click="showDetail(scope.row)">查看</span>
              <!--     草稿/驳回状态的数据可以编辑     -->
              <span class="mgr10 blue" v-if="(scope.row.status===6||scope.row.status===3)&&$reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)"  @click="editData(scope.row.id)">编辑</span>
              <!--     提交但是还没被审核的数据可以撤销        -->
              <span class="mgr10 blue" v-if="scope.row.status===102&&$reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)" @click="repeal(scope.row.id)">撤销</span>
              <!--     驳回的数据可以终止        -->
              <span class="mgr10 blue" v-if="scope.row.status===3&&$reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)" @click="terminate(scope.row.id)">终止</span>
              <!--     审批通过后，可以打印详情           -->
              <span class="mgr10 blue" v-if="scope.row.status===4" @click="_print(scope.row.id)">打印</span>

            </template>
          </el-table-column>
          <el-table-column :label="col.label" width="col.width" v-else-if="col.prop==='status'">
            <template  slot-scope="scope">
               <span class="status" :class=[$reconava.getStatusClass(scope.row.status)] >
                  {{$reconava.getStatusName(scope.row.status)}}</span>
            </template>
          </el-table-column>
          <el-table-column :label="col.label" width="col.width" v-else-if="col.prop==='createUser'">
            <template slot-scope="scope">
              {{ scope.row.createBySysUserName ? scope.row.createBySysUserName.username : "无"}}
            </template>
          </el-table-column>
          <el-table-column :label="col.label"  width="col.width"  v-else-if="col.prop==='sysDeptName'">
            <template slot-scope="scope">
              {{ scope.row.sysDeptName ? scope.row.sysDeptName.deptName : "无" }}
            </template>
          </el-table-column>
          <el-table-column :label="col.label"  width="col.width"  v-else-if="col.prop==='position'">
            <template slot-scope="scope">
              {{scope.row.sysPositionNameList&&scope.row.sysPositionNameList.length?scope.row.sysPositionNameList.map((e) => e.positionName).join(","):''}}
            </template>
          </el-table-column>
          <el-table-column :prop="col.prop" :label="col.label" width="col.width" v-else></el-table-column>
        </template>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total">

      </reconova-pagination>
    </div>
  </div>
</template>

<script>
import reconovaPagination from "@/components/reconova/reconovaPagination";
import {onDownload, operatorDialog} from "@/utils/utils";
export default {
  name: "positive-list",
  components:{
    reconovaPagination
  },
  data(){
    const columns = [
      {prop:'jobNumber',label:'员工编号'},
      {prop:'staffName',label:'姓名'},
      {prop:'createUser',label:'申请人'},
      {prop:'contractsPartA',label:'合同主体'},
      {prop:'sysDeptName',label:'部门'},
      {prop:'position',label:'岗位'},
      {prop:'beginTrailTime',label:'试用开始时间'},
      {prop:'endTrailTime',label:'试用截止时间'},
      {prop:'status',label:'审核状态'},
      {prop:'operate',label:'操作',width:200},
    ]
    return {
      typeId:this.$store.state.PERSONNEL.POSITIVE_TYPEID,
      statusArr:[],
      tableData:[],
      cols:columns,
      filter:{
        name:'',
        status:'',
        no:''
      },
      pageSize:10,
      pageIndex:1,
      total:0
    }
  },
  created() {
    this.getAuditStatus();
    this.search();
  },
  methods:{
    // 获取审核状态
    getAuditStatus(){
      this.statusArr = this.$map.getter('auditStatus');
      this.statusArr.unshift({value:'',label:'全部'})
    },

    getParams(pageSize,pageIndex){
      return {
        ...this.filter,
        pageSize:pageSize,
        pageNum:pageIndex
      }
    },
    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      this.$api.personnel.getPositiveList(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
      })
    },

    paginationChange(type,value){
      let size,index;
      if(type==='pageSize'){
        index = 1;
        size = value;
      }else{
        index = value;
        size = this.pageSize;
      }
      this.search(size,index)
    },

    exportData(){
      this.$api.personnel.positiveExport(Object.assign(this.getParams(10000,1))).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },

    // 重置
    resetSearch(){
      for(let key in this.filter){
        this.filter[key] = ''
      }
      this.search()
    },

    _print(id){
      this.$router.push({path:'/businessModule/personnel/positive/print',query:{id}})
    },

    //  查看详情
    showDetail(data){
      this.$router.push(`/businessModule/personnel/positive/detail/${data.id}`)
    },

    //  编辑
    editData(id){
      this.$router.push(`/businessModule/personnel/positive/edit/${id}`)
    },

    //  新增
    addData(){
      this.$router.push('/businessModule/personnel/positive/edit')
    },

    // 撤销
    repeal(id){
      operatorDialog(this,'理由','撤销',(value)=>{
        let param = {
          id:id,
          typeId:this.typeId,
          reason:value||''
        }
        this.$api.checkapi.repealMyApply(param).then(response=>{
          this.$message.success('已撤销');
          this.search()
        })
      })
    },

    // 终止
    terminate(id){
      operatorDialog(this,'理由','终止',(value)=>{
        let param = {
          id:id,
          typeId:this.typeId,
          reason:value||''
        }
        this.$api.checkapi.terminateMyApply(param).then(response=>{
          this.$message.success('已终止');
          this.search()
        })
      })
    },
  },


}
</script>

<style scoped lang="scss">
.el-table{
  flex: 1;
  overflow-y: auto;
}
.el-input,.el-select{
  width: 220px;
}
.flex{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.inline-flex{
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.inline-block{
  display: inline-block;
}
</style>v