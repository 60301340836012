<template>
  <div>
    <template v-if="$route.path==='/businessModule/personnel/positive'">
      <list></list>
    </template>
    <template  v-if="$route.path.includes('/businessModule/personnel/positive/detail')">
      <detail></detail>
    </template>
    <template v-if="$route.path.includes('/businessModule/personnel/positive/edit')">
      <edit></edit>
    </template>
  </div>
</template>

<script>
import  list from "./list"
import  detail from "./detail"
import  edit from "./edit"
export default {
  name: "positive-index",
  components:{
    list,
    edit,
    detail
  },
  data(){
    return{

    }
  }

}
</script>

<style scoped lang="scss">

</style>